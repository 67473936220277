<template>
  <div class="container">
    <h4 class="text-center my-4">Расписание</h4>

    <div class="my-3">
      <router-link to="/schedule/full" class="ms-2 text-decoration-none">Полное расписание</router-link>
    </div>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">

      <div class="my-3">
        <div class="row mt-4">
          <label class="col-md-3 col-form-label">Уровень обучения</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="filterForm.studyLevelId">
              <option :value="0" hidden>Выберите</option>
              <option v-for="(item, index) in adviserChoose_form.studyLevels" :key="index" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row mt-4">
          <label class="col-md-3 col-form-label">Язык обучения</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="filterForm.studyLanguageId">
              <option :value="0" hidden>Выберите</option>
              <option v-for="(item, index) in studyLanguages" :key="index" :value="item.id">
                {{ item.native_name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row mt-4">
          <label class="col-md-3 col-form-label">Курс</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="filterForm.studyCourse">
              <option :value="0" hidden>Выберите</option>
              <option v-for="(sCourse, sCourseIndex) in studyCourses" :key="sCourseIndex" :value="sCourse">
                {{ sCourse }}
              </option>
            </select>
          </div>
        </div>


        <div class="my-4 text-center">
          <Button icon="pi pi-eye" label="Показать"
                  :disabled="!filterForm.studyLevelId||!filterForm.studyLanguageId||!filterForm.studyCourse"
                  :loading="loadingSchedule"
                  @click="getScheduleData"/>
        </div>
      </div>


      <div v-if="isScheduleLoaded">

        <div v-for="(day, dayIndex) in days" :key="dayIndex" class="my-4">
          <h4>
            {{ day.name_ru }}
          </h4>

          <div class="table-responsive my-2">
            <table class="table table-striped table-bordered">
              <thead>
              <tr>
                <th scope="col" class="w150">Время</th>
                <th scope="col">Выбор</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(intervalTime, intervalTimeIndex) in scheduleIntervalTimes" :key="intervalTimeIndex">
                <th scope="row">{{ intervalTime.time }}</th>
                <td>
                  <div>
                    <Button icon="pi pi-plus" class="p-button-rounded"
                            @click="openAddSchedule(day.id, intervalTime.id)"/>
                  </div>
                  <div class="my-3">
                    <div v-for="(scheduleRow, scheduleRowIndex) in scheduleTable[day.id][intervalTime.id]"
                         :key="scheduleRowIndex"
                         class="rounded-3 py-2 my-2 mx-0 bg-violet text-violet border-left-violet row">
                      <div class="col-md-10">
                        <div>
                          <b class="me-2">{{ scheduleRow.room_name }}</b> {{ scheduleRow.education_discipline_name }}
                          ({{ scheduleRow.language_name }}) - {{ scheduleRow.lastname }} {{ scheduleRow.firstname }}
                        </div>
                        <div class="mt-2">
                          <span class="me-2">{{ scheduleRow.education_type_name }}</span>
                        </div>
                        <div class="mt-1">
                          Количество сидений: {{scheduleRow.count_of_seats}}
                        </div>
                        <div class="mt-1">
                          Количество компьютеров: {{scheduleRow.count_of_computers}}
                        </div>
                      </div>
                      <div class="col-md-1">
                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning"
                                @click="openEditSchedule(scheduleRow.id,day.id,intervalTime.id)"/>
                      </div>
                      <div class="col-md-1">
                        <Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text"
                                @click="deleteSchedule(scheduleRow.id)"/>
                      </div>

                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>

      </div>

    </div>


    <!-- add schedule -->
    <Dialog :header="`${newSchedule.dayName} ${newSchedule.intervalTimeName}`" :visible="displayAddSchedule"
            :closable="false" :modal="true"
            :style="{width: '100%', maxWidth: '1000px'}">
      <div class="row">
        <div class="col-md-7">
          <div class="my-3">
            <div class="my-3">
              <div class="mb-1">Дисциплина</div>
              <Dropdown v-model="newSchedule.disciplineId" :options="disciplines" :filter="true" :showClear="true"
                        optionLabel="name" optionValue="uid"
                        placeholder="Выберите дисциплину" class="w100p"/>
            </div>

            <div class="my-3">
              <div class="mb-1">Преподаватель</div>
              <Dropdown v-model="newSchedule.pps_teaching_load_id" :options="newSchedule.ppsTeachingLoad" :filter="true"
                        :showClear="true"
                        :loading="newSchedule.loadingPpsTeachingLoad"
                        optionLabel="name" optionValue="id"
                        placeholder="Выберите преподавателя" class="w100p"/>
            </div>

            <div class="my-3">
              <div class="mb-1">Аудитория</div>
              <Dropdown v-model="newSchedule.room_id" :options="newSchedule.rooms" :filter="true" :showClear="true"
                        :loading="newSchedule.loadingRooms"
                        optionLabel="name" optionValue="id"
                        placeholder="Выберите аудиторию" class="w100p"/>
            </div>
          </div>

          <div v-if="newSchedule.additionalSchedule.length">
            <div v-for="(aSchedule, aScheduleIndex) in newSchedule.additionalSchedule" :key="aScheduleIndex"
                 class="p-2 my-3 border rounded-3">
              <div class="fw-bold">{{ aScheduleIndex + 2 }} час</div>

              <div>
                <div class="my-3">
                  <div class="mb-1">День</div>
                  <Dropdown v-model="aSchedule.day_id" :options="days"
                            :filter="true" :showClear="true"
                            optionLabel="name_ru" optionValue="id"
                            placeholder="Выберите день" class="w100p"/>
                </div>

                <div class="my-3">
                  <div class="mb-1">Время</div>
                  <Dropdown v-model="aSchedule.schedule_interval_time_id"
                            :options="scheduleIntervalTimes"
                            :filter="true" :showClear="true"
                            optionLabel="time" optionValue="id"
                            placeholder="Выберите время" class="w100p"/>
                </div>

                <div class="my-3">
                  <div class="mb-1">Аудитория</div>
                  <Dropdown v-model="aSchedule.room_id" :options="newSchedule.rooms" :filter="true"
                            :showClear="true"
                            optionLabel="name" optionValue="id"
                            placeholder="Выберите аудиторию" class="w100p"/>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col-md-5">
          <div v-for="(ppsFreeTime, ppsFreeTimeKey) in ppsFreeTimes" :key="'ppsFreeTimeKey'+ppsFreeTimeKey"
               class="my-2 border rounded-3 p-2">
            <h5 class="mb-2">
              <router-link :to="`/schedule/user/${ppsFreeTimeKey}`" target="_blank" class="text-decoration-none">
                {{ ppsFreeTime.name }}
              </router-link>
            </h5>
            <div class="row">
              <div v-for="(day, dayKey) in ppsFreeTime.days" :key="'dayKey'+dayKey" class="col-md-4 mb-2">
                <div class="fw-bold">{{ day[0].name_ru }}</div>
                <div v-for="(time, timeIndex) in day" :key="'timeIndex'+timeIndex" class="ps-2">
                  {{ time.time }}
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" @click="closeAddSchedule" class="p-button-text"/>
        <Button label="Сохранить" icon="pi pi-save"
                :disabled="newScheduleDisabled"
                :loading="loadingAddSchedule"
                @click="addSchedule"
                autofocus/>
      </template>
    </Dialog>
    <!-- end add schedule -->


    <!-- edit schedule -->
    <Dialog
      :header="`${editSchedule?.room_name} ${editSchedule?.education_discipline_name} (${editSchedule?.language_name}) - ${editSchedule?.lastname} (${editSchedule?.education_type_name}) Кол: ${editSchedule?.students_count} U${editSchedule?.union_course_id}`"
      :visible="displayEditSchedule"
      :closable="false" :modal="true"
      :style="{width: '100%', maxWidth: '500px'}">

      <div>
        <div class="my-3">
          <div class="mb-1">День</div>
          <Dropdown v-model="editSchedule.day_id" :options="days"
                    :filter="true" :showClear="true"
                    optionLabel="name_ru" optionValue="id"
                    placeholder="Выберите день" class="w100p"/>
        </div>

        <div class="my-3">
          <div class="mb-1">Время</div>
          <Dropdown v-model="editSchedule.schedule_interval_time_id"
                    :options="scheduleIntervalTimes"
                    :filter="true" :showClear="true"
                    optionLabel="time" optionValue="id"
                    placeholder="Выберите время" class="w100p"/>
        </div>

        <div class="my-3">
          <div class="mb-1">Аудитория</div>
          <Dropdown v-model="editSchedule.room_id" :options="editSchedule.rooms" :filter="true"
                    :showClear="true"
                    optionLabel="name" optionValue="id"
                    placeholder="Выберите аудиторию" class="w100p"/>
        </div>
      </div>


      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" @click="closeEditSchedule" class="p-button-text"/>
        <Button label="Сохранить" icon="pi pi-save"
                :loading="loadingEditSchedule"
                @click="saveEditSchedule"
                autofocus/>
      </template>
    </Dialog>
    <!-- end edit schedule -->

  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'


export default {
  name: 'ManagerSchedule',
  data() {
    return {
      page: +this.$route.query.page || 1,
      loading: true,
      studyCourses: [1, 2, 3, 4],
      filterForm: {
        studyLevelId: 0,
        studyLanguageId: 0,
        studyCourse: 0,
      },
      loadingSchedule: false,
      isScheduleLoaded: false,
      disciplines: [],
      displayAddSchedule: false,
      loadingAddSchedule: false,
      newSchedule: {},
      ppsFreeTimes: {},
      scheduleTable: {},

      displayEditSchedule: false,
      editSchedule: {},
      loadingEditSchedule: false,
    }
  },
  computed: {
    ...mapState('managerSchedule', ['days', 'scheduleIntervalTimes']),
    ...mapState('adviserChoose', ['adviserChoose_form']),
    ...mapState('departmentPractice', ['studyLanguages']),
    newScheduleDisabled() {
      return !this.newSchedule.pps_teaching_load_id || !this.newSchedule.room_id
    }
  },
  watch: {
    'filterForm.studyLevelId'() {
      this.isScheduleLoaded = false
    },
    'filterForm.studyLanguageId'() {
      this.isScheduleLoaded = false
    },
    'filterForm.studyCourse'() {
      this.isScheduleLoaded = false
    },
    async 'newSchedule.disciplineId'() {
      this.newSchedule.loadingPpsTeachingLoad = true

      this.newSchedule.pps_teaching_load_id = null
      this.newSchedule.ppsTeachingLoad = []
      this.newSchedule.room_id = null
      this.newSchedule.rooms = []

      this.newSchedule.additionalSchedule = []

      const discipline = this.disciplines.find(i => i.uid == this.newSchedule.disciplineId)

      this.newSchedule.need_computer = discipline?.need_computer
      if (this.newSchedule.disciplineId) {
        this.newSchedule.ppsTeachingLoad = await this.GET_PPS_TEACHING_LOAD_BY_DISCIPLINE_ID({
          education_discipline_id: discipline.id,
          day_id: this.newSchedule.day_id,
          schedule_interval_time_id: this.newSchedule.schedule_interval_time_id,
          credit: discipline.credit,
          semester: discipline.semester
        })
        await this.getPpsFreeTime(discipline.id)
      }

      this.newSchedule.loadingPpsTeachingLoad = false
    },
    async 'newSchedule.pps_teaching_load_id'() {
      this.newSchedule.loadingRooms = true

      this.newSchedule.room_id = null
      this.newSchedule.rooms = []
      this.newSchedule.additionalSchedule = []

      if (this.newSchedule.pps_teaching_load_id) {
        const ppsTeachingLoad = this.newSchedule.ppsTeachingLoad.find(i => i.id === this.newSchedule.pps_teaching_load_id)

        if (ppsTeachingLoad.count > 1) {
          for (let i = 1; i < ppsTeachingLoad.count; i++) {
            this.newSchedule.additionalSchedule.push({
              day_id: null,
              schedule_interval_time_id: null,
              room_id: null
            })
          }
        }

        this.newSchedule.rooms = await this.getFreeRooms(ppsTeachingLoad?.students_count, this.newSchedule.day_id, this.newSchedule.schedule_interval_time_id, this.newSchedule.need_computer)
      }
      this.newSchedule.loadingRooms = false
    },
  },
  methods: {
    ...mapActions('managerSchedule', ['GET_DAYS', 'GET_SCHEDULE_INTERVAL_TIMES',
      'GET_DISCIPLINE_SCHEDULE', 'GET_PPS_TEACHING_LOAD_BY_DISCIPLINE_ID',
      'GET_FREE_ROOMS',
      'GET_SCHEDULE', 'POST_SCHEDULE', 'DELETE_SCHEDULE',
      'GET_PPS_FREE_TIME', 'PUT_SCHEDULE'
    ]),
    ...mapActions('adviserChoose', ['GET_STUDY_LEVELS']),
    ...mapActions('departmentPractice', ['GET_STUDY_LANGUAGES']),
    async getScheduleData() {
      this.loadingSchedule = true
      this.isScheduleLoaded = false

      const disciplines = await this.GET_DISCIPLINE_SCHEDULE(this.getScheduleFilterForm())
      this.disciplines = disciplines.map(i => ({
        ...i,
        uid: i.id + 'c' + i.credit
      }))

      await this.getSchedule()

      this.isScheduleLoaded = true
      this.loadingSchedule = false
    },
    openAddSchedule(day_id, schedule_interval_time_id) {
      this.newSchedule = {
        day_id,
        schedule_interval_time_id,
        dayName: this.days.find(i => i.id === day_id).name_ru,
        intervalTimeName: this.scheduleIntervalTimes.find(i => i.id === schedule_interval_time_id).time,
        disciplineId: null,
        ppsTeachingLoad: [],
        pps_teaching_load_id: null,
        rooms: [],
        room_id: null,
        loadingPpsTeachingLoad: false,
        loadingRooms: false,
        need_computer: null,
        additionalSchedule: [],
      }

      this.ppsFreeTimes = {}
      this.displayAddSchedule = true
    },
    closeAddSchedule() {
      this.displayAddSchedule = false
    },
    async addSchedule() {
      this.loadingAddSchedule = true

      const form = [{
        pps_teaching_load_id: this.newSchedule.pps_teaching_load_id,
        day_id: this.newSchedule.day_id,
        schedule_interval_time_id: this.newSchedule.schedule_interval_time_id,
        room_id: this.newSchedule.room_id
      }]

      this.newSchedule.additionalSchedule.forEach(s => {
        form.push({
          ...s,
          pps_teaching_load_id: this.newSchedule.pps_teaching_load_id
        })
      })

      const res = await this.POST_SCHEDULE(form)

      if (res) {
        await this.getSchedule()
        this.closeAddSchedule()
        this.$message({title: 'Сохранено'})
      }

      this.loadingAddSchedule = false
    },
    async getFreeRooms(count_of_student, day_id, schedule_interval_time_id, need_computer) {
      const res = await this.GET_FREE_ROOMS({count_of_student, day_id, schedule_interval_time_id, need_computer})
      return res.map(i=>({...i, name: `Аудитория ${i.name} - Количество сидений ${i.count_of_seats} - Количество компьютеров ${i.count_of_computers}`}))
    },
    getScheduleFilterForm() {
      return {
        study_level_id: this.filterForm.studyLevelId,
        language_id: this.filterForm.studyLanguageId,
        study_course: this.filterForm.studyCourse
      }
    },
    async getSchedule() {
      const schedule = await this.GET_SCHEDULE(this.getScheduleFilterForm())

      const scheduleTable = {}
      for (let day of this.days) {
        scheduleTable[day.id] = {}
        for (let intervalTime of this.scheduleIntervalTimes) {
          scheduleTable[day.id][intervalTime.id] = schedule.filter(i => i.day_id == day.id).filter(i => i.schedule_interval_time_id == intervalTime.id)
        }
      }

      this.scheduleTable = scheduleTable
    },
    async getPpsFreeTime(disciplineId) {
      const ppsFreeTime = await this.GET_PPS_FREE_TIME(disciplineId)
      const user = {}

      ppsFreeTime.forEach(i => {

        if (!user[i.user_id]) {
          user[i.user_id] = {
            days: {},
            name: `${i.lastname} ${i.firstname}`
          }
        }

        if (!user[i.user_id]['days'][i.sp_days_id]) {
          user[i.user_id]['days'][i.sp_days_id] = []
        }

        user[i.user_id]['days'][i.sp_days_id].push(i)
      })

      this.ppsFreeTimes = user
    },
    async deleteSchedule(id) {
      const res = await this.DELETE_SCHEDULE(id)
      if (res) {
        await this.getSchedule()
        this.$message({title: 'Успешно удалено'})
      }
    },
    async openEditSchedule(scheduleId, dayId, intervalTimeId) {
      this.editSchedule = {...this.scheduleTable[dayId][intervalTimeId].find(i => i.id === scheduleId)}

      this.editSchedule.rooms = await this.getFreeRooms(this.editSchedule.students_count, this.editSchedule.day_id, this.editSchedule.schedule_interval_time_id, 0)

      this.displayEditSchedule = true
    },
    closeEditSchedule() {
      this.displayEditSchedule = false
    },
    async saveEditSchedule() {
      this.loadingEditSchedule = true
      const res = await this.PUT_SCHEDULE({
        id: this.editSchedule.id,
        room_id: this.editSchedule.room_id,
        day_id: this.editSchedule.day_id,
        schedule_interval_time_id: this.editSchedule.schedule_interval_time_id
      })

      if (res) {
        await this.getScheduleData()
        this.closeEditSchedule()
      }
      this.loadingEditSchedule = false
    }
  },
  async mounted() {
    await this.GET_STUDY_LEVELS()
    await this.GET_STUDY_LANGUAGES()

    await this.GET_DAYS()
    await this.GET_SCHEDULE_INTERVAL_TIMES()


    this.loading = false
  },
}

</script>

<style scoped>
.w150 {
  width: 150px;
}

.w100p {
  width: 100%;
}

.text-violet {
  color: #444791;
}

.bg-violet {
  background-color: #e8ebfa;
}

.border-left-violet {
  border-left: 5px solid #5b5fc7;
}

</style>
